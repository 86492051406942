<template>
  <main id="app">
    <div class="myInvitation">
      <img :src="myInvitationImg" alt="">
      <div class="share-img-text">
        <span>长按我，转发给朋友</span>
      </div>
      <div class="share-img-text-second">
        <span>或</span>
      </div>
      <div class="share-link-text">
        <span class="confirm confirm-copy" :data-clipboard-text="'http://m.bncc.com/Invitation?invitationCode='+userInfo.id" @click="copyConfirm">复制链接</span>
      </div>

    </div>
    <div class="myInvitation-main">

      <ul class="invitation_ul">
        <li @click="tabOne" :class="showTabOne?'active':''">活动流程</li>
        <li @click="tabTwo" :class="showTabTwo?'active':''">我的邀请</li>
      </ul>
      <div class="invitation_liucheng" v-show="showTabOne">
        <div class="liuct">
          <a href="#" id="invite" @click="clickLiuCheng(1)">
            <img  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/2552278aa477ed1e7c19e7ee5e58dd38.png" alt="">
          </a>

          <a class="regist" href="#"  id="registerj" @click="clickLiuCheng(2)">
            <span></span>
            <img  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/8f6dcf25855efe89cb6c8fa95a385a6a.png" alt="">
          </a>

          <a class="rewar" href="#"  id="reward" @click="clickLiuCheng(3)">
            <span></span>
            <img  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/3a6c9cce01df85d4dffea53f90a2212b.png" alt="">
          </a>

        </div>
        <ol class="pic-info">
          <span id="sanjiao" v-show="txtShow == 1"></span>
          <li id="invitationT_txt" v-show="txtShow == 1">
            <div>
              <span>分享邀请码给朋友，500元红包等你拿</span>
              <span>长按二维码转发给朋友，邀请好友注册或登录。</span>
            </div>
          </li>

          <span id="sanjiao2" v-show="txtShow == 2"></span>
          <li id="invitationT-registerj" v-show="txtShow == 2">
            <div>
              <span>绑定推荐关系</span>
              <span> 好友扫码后注册或登录，即与您绑定推荐关系。此时您和您朋友均可获赠200元优惠券。</span>
            </div>
          </li>

          <span id="sanjiao3" v-show="txtShow == 3"></span>
          <li id="invitationT-reward" v-show="txtShow == 3">
            <div>
              <span>获得500元红包奖励</span>
              <span>当好友下单累积达5000元，您可领取500元现金红包。</span>
            </div>
          </li>
        </ol>
        <!-- <el-input v-model="shareUrl" :title="shareUrl"></el-input>-->
      </div>
      <div class="invitation_record" v-show="showTabTwo">
        <list url="/M/User/myInvitationRecord" :show="true">
          <template v-slot:default="data">
            <invitation-item
                    :invitationData="data"
            ></invitation-item>
          </template>
        </list>
      </div>
    </div>

  </main>
</template>

<script lang="ts">
  import {defineComponent, inject, nextTick, onMounted, ref, watch} from "vue";
  import list from "@/components/common/list.vue";
  import invitationItem from "@/components/common/InvitationItem.vue";
  import wechatLogin from "@/api/wechatLogin";
  import {useRoute, useRouter} from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import $ from "jquery";
  import useClipboard from 'vue-clipboard3'
  import Clipboard from "clipboard";

  export default defineComponent({
    name: "myInvitation",
    components: {
      list,
      invitationItem
    },

    setup() {

      const route = useRoute();
      const myInvitationImg = ref()
      const userInfo = inject("userInfo") as any;
      const showTabOne = ref(true);
      const showTabTwo = ref(false);
      const txtShow = ref(1);
      const urlData = ref();
      const router = useRouter();
      function toLogin() {
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.value.fullPath
          }
        });
      }
      function copyConfirm() {
        if(!userInfo || !userInfo.id){
          toLogin();
          return false;
        }
        const clipboard = new Clipboard('.confirm-copy');
        clipboard.on('success', (e) => {
          Toast({
            type: "success",
            title: "已复制，快去发给好友吧~",
            duration:2000,
          });
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          Toast({
            type: "error",
            title: "链接拷贝失败",
          });
          // 释放内存
          clipboard.destroy()
        })
      }

      wechatLogin(route.query.code as string, route.query.state as string)
              .then((res: any) => {
                if (typeof res === "object" && res.data.success) {
                  const token = localStorage.getItem('token') || '';
                  // console.log("token-wx",token)
                  if(token){
                    // console.log("token2222222-wx")
                    myInvitationImg.value="/M/Server/createInvitationImg?token="+token;

                  }
                }
                // initData();
              })
              .catch((e) => {
                console.log(e, "error, -----------------------");
              });


      // 二选一tab栏切换
      function tabOne() {
        showTabOne.value = true;
        showTabTwo.value= false;

      }
      // 二选一tab栏切换
      function  tabTwo() {
        showTabTwo.value = true;
        showTabOne.value= false;

      }
      // 二选一tab栏切换
      function  clickLiuCheng(txtFlag: number) {
        txtShow.value =txtFlag;
      }
      onMounted(() => {
        nextTick(() => {

          const token = localStorage.getItem('token') || '';
          // console.log("token",token)
          if(token){
            // console.log("token2222222")
            myInvitationImg.value="/M/Server/createInvitationImg?token="+token;
          }
        });
      });

      return {
        myInvitationImg,
        userInfo,
        tabOne,
        tabTwo,
        clickLiuCheng,
        txtShow,
        showTabOne,
        showTabTwo,
        copyConfirm,
        urlData

      };
    },

  });
</script>

<style lang="scss" scoped>
  main{
    font-size: 16px;
    margin-bottom: 60px ;
  }

  .myInvitation {
    //width: 280px;
    height: 11rem;
    padding: 0;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    //background-image: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/d313549f5ffe39615fcb390406097ce8.png');
    //background-image: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f328fd0f9b77d17d9368418f29eb675e.png');
    background-image: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/shortTime/9dbad08ce61a70b5c724936f6d99bfaf.jpg');
    position: relative;
  }

  .myInvitation > img {
    width: 4.2rem;
    position: absolute;
    left: 51%;
    top: 49%;
    transform: translate(-50%, -50%);
  }

  .share-img-text {
    position: absolute;
    left: 51%;
    top: 74%;
    transform: translate(-50%, -50%);
    color: #faf783;
    font-size: 18px;
  }
  .share-img-text >span {
    text-align: center;
  }
  .share-img-text-second {
    position: absolute;
    left: 51%;
    top: 81%;
    transform: translate(-50%, -50%);
    color: #faf783;
    font-size: 18px;
  }
  .share-img-text-second >span {
    text-align: center;
  }

  .share-link-text {
    position: absolute;
    left: 51%;
    top: 89%;
    transform: translate(-50%, -50%);
    color: #faf783;
  }
  .share-link-text >span {
    display: inline-block;
    text-align: center;
    background-color:#faf783;
    border-radius: 0.83333rem;
    color: RGB(209,51,38);
    width: 5.2rem;
    height: 0.9rem;
    line-height: 0.9rem;
    font-weight: 600;
  }


  .myInvitation-main{
    background: #f5f5f5;
    padding-bottom: 50px;
  }
  .active{
    color:#df0024
  }
  .invitation_liucheng{
    width: 90%;
    height: 375px;
    background: rgb(255, 255, 255);
    margin: 0px auto;
    padding-bottom: 20px;
  }
  .liuct{
    width:85%;
    height: 85px;
    margin: 0 auto;
    padding-top: 25px;

  }
  .liuct a{
    float:left;
  }
  .liuct a span{
    border: 1px dashed  #df0024;
    display: inline-block;
    width:45px;
    vertical-align: -webkit-baseline-middle;
  }
  .liuct a img{
    margin-right: 0.15rem;
    vertical-align: -webkit-baseline-middle;
  }
  .regist{
    width:40%;
    margin-left: 6px;
  }
  .regist img{
    margin-left: 12px;
    vertical-align: -webkit-baseline-middle;
  }
  .rewar{
    width:38%;float:right
  }
  .rewar img{
    margin-left: 5px;
    vertical-align: -webkit-baseline-middle;
  }
  .pic-info{
    /*width: 6.3rem;*/
    width: 95%;
    text-align: center;
    /*margin: 0 auto;*/
    display: flex;
    justify-content: space-between;
    margin-top:0;
    position: relative;

  }
  .pic-info li{
    padding-top: 0.1rem;

    margin-top: 0.2rem;

  }
  .pic-info li div{
    text-align: left;
  }
  .pic-info li div span{
    display: block;
    font-size: 14px;
  }
  .pic-info li div span:nth-child(1){
    color:#df0024;
    font-size: 16px;
    margin-top:0.3rem;
    font-weight: bold;
  }
  .pic-info li div span:nth-child(2){
    margin-top: 0.25rem;
    color:#666;
  }
  .pic-info li div span:nth-child(3){
    margin-top:0.25rem;
    color:#666;
  }
  #sanjiao{
    display: inline-block;
    width:0;
    height: 0;
    border-width: 8px 8px 0;
    border-style: solid;
    border-color: #df0024 transparent transparent;
    position: absolute;
    left:40px;
    top:0px;
  }
  #sanjiao2{
    display: inline-block;
    width: 0;
    height: 0;
    border-width: 8px 8px 0;
    border-style: solid;
    border-color: #df0024 transparent transparent;
    position: absolute;
    left: 160px;
    top: 0;
  }
  #sanjiao3{
    display: inline-block;
    width:0;
    height: 0;
    border-width: 8px 8px 0;
    border-style: solid;
    border-color: #df0024 transparent transparent;
    position: absolute;
    left:267px;
    top:0px;
  }
  .invitation_liucheng a  img{
    width:50px;
    //height: 64px;
  }
  .myInvitation-main ul{
    width:100%;
    height: 40px;

  }
  .invitation_ul li{
    width:25%;
    height: 40px;
    line-height: 40px;
    float:left;
    text-align: center;
    font-size: 16px;
  }
  .myInvitation-text {
    padding: 20px;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .myInvitation-text > p:nth-child(1) {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
    color: red;
  }
  .ly_user_invitationStatistics{
    width:90%;
    margin: 0 auto;
    background: #fff;
  }
  .ly_user_invitationStatistics>p:nth-child(1) {
    font-size: 16px;
    margin: 20px 0;
  }
  .ly_user_invitationStatistics>p:nth-child(1):before {
    content: "|";
    background-color: #df0024;
    color: #df0024;
    font-weight: bold;
    margin-right: 0.1rem;
  }
  table {
    width: 100%;
    border: 1px solid black;
    margin: 0 auto;
    border-collapse: collapse;
    font-size: 16px;
  }
  td, th {
    width: 95px;
    height: 50px;
    border: 1px solid #eee;
    text-align: center;
    font-weight: normal;
    vertical-align: center;
  }
  th {
    background: rgb(245,245,245);
  }

</style>
