
  import {defineComponent, inject, nextTick, onMounted, ref, watch} from "vue";
  import list from "@/components/common/list.vue";
  import invitationItem from "@/components/common/InvitationItem.vue";
  import wechatLogin from "@/api/wechatLogin";
  import {useRoute, useRouter} from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import $ from "jquery";
  import useClipboard from 'vue-clipboard3'
  import Clipboard from "clipboard";

  export default defineComponent({
    name: "myInvitation",
    components: {
      list,
      invitationItem
    },

    setup() {

      const route = useRoute();
      const myInvitationImg = ref()
      const userInfo = inject("userInfo") as any;
      const showTabOne = ref(true);
      const showTabTwo = ref(false);
      const txtShow = ref(1);
      const urlData = ref();
      const router = useRouter();
      function toLogin() {
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.value.fullPath
          }
        });
      }
      function copyConfirm() {
        if(!userInfo || !userInfo.id){
          toLogin();
          return false;
        }
        const clipboard = new Clipboard('.confirm-copy');
        clipboard.on('success', (e) => {
          Toast({
            type: "success",
            title: "已复制，快去发给好友吧~",
            duration:2000,
          });
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          Toast({
            type: "error",
            title: "链接拷贝失败",
          });
          // 释放内存
          clipboard.destroy()
        })
      }

      wechatLogin(route.query.code as string, route.query.state as string)
              .then((res: any) => {
                if (typeof res === "object" && res.data.success) {
                  const token = localStorage.getItem('token') || '';
                  // console.log("token-wx",token)
                  if(token){
                    // console.log("token2222222-wx")
                    myInvitationImg.value="/M/Server/createInvitationImg?token="+token;

                  }
                }
                // initData();
              })
              .catch((e) => {
                console.log(e, "error, -----------------------");
              });


      // 二选一tab栏切换
      function tabOne() {
        showTabOne.value = true;
        showTabTwo.value= false;

      }
      // 二选一tab栏切换
      function  tabTwo() {
        showTabTwo.value = true;
        showTabOne.value= false;

      }
      // 二选一tab栏切换
      function  clickLiuCheng(txtFlag: number) {
        txtShow.value =txtFlag;
      }
      onMounted(() => {
        nextTick(() => {

          const token = localStorage.getItem('token') || '';
          // console.log("token",token)
          if(token){
            // console.log("token2222222")
            myInvitationImg.value="/M/Server/createInvitationImg?token="+token;
          }
        });
      });

      return {
        myInvitationImg,
        userInfo,
        tabOne,
        tabTwo,
        clickLiuCheng,
        txtShow,
        showTabOne,
        showTabTwo,
        copyConfirm,
        urlData

      };
    },

  });
